import {AmenityFeatureType, AmenitySection} from 'src/types/amenities'
import {accessibility} from './accessibility'
import {coffee} from './coffee'
import {familyFeatures} from './family-features'
import {indoorFeatures} from './indoor-features'
import {kitchen} from './kitchen'
import {outdoorFeatures} from './outdoor-features'
import {services} from './services'
import {sports} from './sports'
import {exclusion} from './exclusion'

export const amenitySections: AmenitySection[] = [
  outdoorFeatures,
  sports,
  indoorFeatures,
  kitchen,
  coffee,
  familyFeatures,
  accessibility,
  services,
  exclusion,
]

//merge all sections features
export const allAmenities: AmenityFeatureType[] = amenitySections.reduce(
  (acc: AmenityFeatureType[], s: AmenitySection) => acc.concat(s.features),
  [],
)

export const allAmenitiesMap: {[key: string]: AmenityFeatureType} =
  allAmenities.reduce(
    (acc: {[key: string]: AmenityFeatureType}, a: AmenityFeatureType) => {
      //log error to warn devs
      if (typeof acc[a.label] !== 'undefined') {
        // eslint-disable-next-line no-console
        console.error('Duplicate amenities slug', a)
      }
      acc[a.label] = a
      return acc
    },
    {},
  )

export const convertAmenitySlugsToAmenities = (
  slugs: string[],
): AmenityFeatureType[] => {
  return slugs.reduce((acc: AmenityFeatureType[], slug: string) => {
    const item = allAmenitiesMap[slug] ?? null
    if (item) {
      acc.push(item)
    }
    return acc
  }, [])
}

export const convertAmenitySlugsToAmenitySections = (
  slugs: string[],
): AmenitySection[] => {
  const sections: AmenitySection[] = []

  amenitySections.forEach((section) => {
    let newSection: AmenitySection = {
      title: section.title,
      features: [], //start empty
    }

    //fill arr if it's in slugs
    section.features.forEach((feature) => {
      if (slugs.includes(feature.label)) {
        newSection.features.push(feature)
      }
    })

    //only add if empty not empty
    if (newSection.features.length > 0) {
      sections.push(newSection)
    }
  })

  return sections
}
