import {http} from 'src/services/http'
import {CancelToken} from 'axios'
import {
  AutocompleteAddressLocationType,
  AutocompleteLocationType,
} from 'src/types/location'
import {LocaleType} from 'src/types/utils'

export async function publicGetLocationAutocompleteSearchApi(
  searchValue: string,
  cancelToken?: CancelToken,
) {
  const {data} = await http.request({
    method: 'GET',
    url: '/public/location/search-autocomplete',
    params: {
      search: searchValue,
    },
    cancelToken: cancelToken,
  })
  return data as {success: boolean; results: AutocompleteLocationType[]}
}

export async function publicGetAddressLocationAutocompleteSearchApi(
  searchValue: string,
  cancelToken?: CancelToken,
) {
  const {data} = await http.request({
    method: 'GET',
    url: '/public/location/address-autocomplete',
    params: {
      search: searchValue,
    },
    cancelToken: cancelToken,
  })
  return data as {success: boolean; results: AutocompleteAddressLocationType[]}
}

export async function getLocationDataApi(
  address: string,
  locale: LocaleType,
  cancelToken?: CancelToken,
) {
  const {data} = await http.request({
    method: 'GET',
    url: '/location',
    params: {
      full_address: address,
      locale: locale,
    },
    cancelToken: cancelToken,
  })
  return data as {success: boolean; data: any}
}
