import AmenityIcon from 'src/components/elements/icons/AmenityIcon'
import AppTypography from 'src/components/elements/typography/AppTypography'
import {AmenityFeatureType} from 'src/types/amenities'
import {useTranslation} from 'next-i18next'

function AmenityFeatureRow(props: {
  amenity: AmenityFeatureType
  isAction?: boolean
}) {
  const {amenity, isAction = true} = props
  const {t} = useTranslation('amenity')

  return (
    <div className="flex flex-row justify-start items-center gap-3">
      <AmenityIcon amenity={amenity} />

      <AppTypography
        component="span"
        neutralColor={isAction ? 800 : 500}
        variant={isAction ? 'action' : 'body'}
        className="leading-tight"
      >
        {t(amenity.label)}
      </AppTypography>
    </div>
  )
}

export default AmenityFeatureRow
